.skills {
  display: flex;
  justify-content: center;

  font-size: 8vh;
  font-weight: bolder;
}
.icons {
  display: flex;
  justify-content: space-between;
  /* align-items: stretch; */
  margin: 5px;
  font-size: 3rem;

  flex-wrap: wrap;
}

#html {
  color: orange;
}

#css {
  color: blue;
}

#react {
  color: skyblue;
}

#python {
  color: rgb(216, 216, 17);
}

#node {
  color: green;
}

#github {
  color: black;
}

#networking {
  color: black;
}

#javascript {
  color: orange;
}
